/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/light";

const $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.setOptions({
  syntax: "proto3"
})
.addJSON({
  ProtoMessage: {
    options: {
      java_package: "com.proto"
    },
    nested: {
      ReqActivityDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResActivityDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          ActivityList: {
            rule: "repeated",
            type: "ActivityInfo",
            id: 3
          }
        }
      },
      ActivityInfo: {
        fields: {
          cId: {
            type: "int32",
            id: 1
          },
          activityId: {
            type: "int32",
            id: 2
          },
          activityType: {
            type: "int32",
            id: 3
          },
          title: {
            type: "string",
            id: 4
          },
          iconUrl: {
            type: "string",
            id: 5
          },
          pictureUrl: {
            type: "string",
            id: 6
          },
          content: {
            type: "string",
            id: 7
          },
          settlementCycle: {
            type: "int32",
            id: 8
          },
          apply: {
            type: "bool",
            id: 9
          },
          gameType: {
            type: "int32",
            id: 11
          },
          conditionList: {
            rule: "repeated",
            type: "Condition",
            id: 12
          },
          startTime: {
            type: "int64",
            id: 13
          },
          endTime: {
            type: "int64",
            id: 14
          },
          curValue: {
            type: "double",
            id: 15
          },
          curValue1: {
            type: "double",
            id: 16
          }
        }
      },
      Condition: {
        fields: {
          recharge: {
            type: "double",
            id: 1
          },
          wagered: {
            type: "double",
            id: 2
          },
          vipLevel: {
            type: "int32",
            id: 3
          },
          minValue: {
            type: "double",
            id: 4
          },
          maxValue: {
            type: "double",
            id: 5
          },
          rewardType: {
            type: "int32",
            id: 6
          },
          bonus: {
            type: "double",
            id: 7
          }
        }
      },
      ReqActivityApplyDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResActivityApplyDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          ApplyRecodeList: {
            rule: "repeated",
            type: "ApplyRecode",
            id: 3
          }
        }
      },
      ApplyRecode: {
        fields: {
          activityId: {
            type: "int32",
            id: 1
          },
          createTime: {
            type: "int64",
            id: 2
          },
          status: {
            type: "int32",
            id: 3
          }
        }
      },
      ReqApplyActivityMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          cId: {
            type: "int32",
            id: 2
          },
          activityId: {
            type: "int32",
            id: 3
          }
        }
      },
      ResApplyActivityMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqWelfareDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          status: {
            type: "int32",
            id: 2
          },
          page: {
            type: "int32",
            id: 4
          },
          pageSize: {
            type: "int32",
            id: 5
          },
          statTime: {
            type: "int64",
            id: 6
          },
          endTime: {
            type: "int64",
            id: 7
          }
        }
      },
      ResWelfareDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          status: {
            type: "int32",
            id: 3
          },
          page: {
            type: "int32",
            id: 4
          },
          pageSize: {
            type: "int32",
            id: 5
          },
          total: {
            type: "int32",
            id: 6
          },
          totalPage: {
            type: "int32",
            id: 7
          },
          available: {
            type: "double",
            id: 8
          },
          totalReceived: {
            type: "double",
            id: 9
          },
          welfareList: {
            rule: "repeated",
            type: "WelfareInfo",
            id: 10
          }
        }
      },
      WelfareInfo: {
        fields: {
          noteId: {
            type: "string",
            id: 1
          },
          welfareType: {
            type: "int32",
            id: 2
          },
          amount: {
            type: "double",
            id: 3
          },
          createTime: {
            type: "int64",
            id: 4
          },
          status: {
            type: "int32",
            id: 5
          },
          welfareDesc: {
            type: "string",
            id: 6
          }
        }
      },
      ReqReceiveWelfareMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          noteId: {
            type: "string",
            id: 2
          }
        }
      },
      ResReceiveWelfareMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          noteId: {
            type: "string",
            id: 3
          },
          amount: {
            type: "double",
            id: 4
          },
          status: {
            type: "int32",
            id: 5
          }
        }
      },
      ReqBindSuperiorMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          referrerCode: {
            type: "string",
            id: 2
          }
        }
      },
      ResBindSuperiorMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          referrerId: {
            type: "int64",
            id: 3
          }
        }
      },
      ReqCommissionRewardDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          gameType: {
            type: "int32",
            id: 2
          },
          past: {
            type: "int32",
            id: 3
          },
          page: {
            type: "int32",
            id: 4
          },
          pageSize: {
            type: "int32",
            id: 5
          },
          statTime: {
            type: "int64",
            id: 6
          },
          endTime: {
            type: "int64",
            id: 7
          },
          type: {
            type: "int32",
            id: 8
          }
        }
      },
      ResCommissionRewardDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          gameType: {
            type: "int32",
            id: 3
          },
          past: {
            type: "int32",
            id: 4
          },
          page: {
            type: "int32",
            id: 5
          },
          pageSize: {
            type: "int32",
            id: 6
          },
          total: {
            type: "int32",
            id: 7
          },
          totalPage: {
            type: "int32",
            id: 8
          },
          available: {
            type: "double",
            id: 9
          },
          totalReceived: {
            type: "double",
            id: 10
          },
          commissionRewardList: {
            rule: "repeated",
            type: "CommissionRewardInfo",
            id: 11
          }
        }
      },
      CommissionRewardInfo: {
        fields: {
          platformId: {
            type: "int32",
            id: 1
          },
          platformName: {
            type: "string",
            id: 2
          },
          currencyId: {
            type: "int32",
            id: 3
          },
          amount: {
            type: "double",
            id: 4
          },
          status: {
            type: "int32",
            id: 5
          },
          time: {
            type: "int64",
            id: 6
          },
          receiveTime: {
            type: "int64",
            id: 7
          }
        }
      },
      ReqConfigDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          host: {
            type: "string",
            id: 2
          }
        }
      },
      ResConfigDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          headList: {
            rule: "repeated",
            type: "HeadInfo",
            id: 3
          },
          vipClubList: {
            rule: "repeated",
            type: "VipClubInfo",
            id: 4
          },
          bannerList: {
            rule: "repeated",
            type: "BannerInfo",
            id: 5
          },
          exchangeRateInfo: {
            type: "ExchangeRateInfo",
            id: 6
          },
          bulletinList: {
            rule: "repeated",
            type: "BulletinInfo",
            id: 7
          },
          popupList: {
            rule: "repeated",
            type: "PopupInfo",
            id: 8
          },
          articlesList: {
            rule: "repeated",
            type: "Articles",
            id: 9
          },
          customerServiceList: {
            rule: "repeated",
            type: "CustomerService",
            id: 10
          },
          agentAddress: {
            type: "string",
            id: 11
          },
          bankList: {
            rule: "repeated",
            type: "string",
            id: 12
          },
          gameTypeList: {
            rule: "repeated",
            type: "GameTypeInfo",
            id: 13
          },
          PlatformGameList: {
            rule: "repeated",
            type: "PlatformGameType",
            id: 14
          }
        }
      },
      PlatformGameType: {
        fields: {
          platformId: {
            type: "int32",
            id: 1
          },
          platformName: {
            type: "string",
            id: 2
          },
          gameType: {
            type: "int32",
            id: 3
          }
        }
      },
      GameTypeInfo: {
        fields: {
          gameType: {
            type: "int32",
            id: 1
          },
          name: {
            type: "string",
            id: 2
          }
        }
      },
      HeadInfo: {
        fields: {
          headId: {
            type: "int32",
            id: 1
          },
          fileUrl: {
            type: "string",
            id: 2
          }
        }
      },
      VipClubInfo: {
        fields: {
          icon: {
            type: "string",
            id: 1
          },
          vipName: {
            type: "string",
            id: 2
          },
          vipLevel: {
            type: "int32",
            id: 3
          },
          needRecharge: {
            type: "double",
            id: 4
          },
          needWager: {
            type: "double",
            id: 5
          },
          relegationWager: {
            type: "double",
            id: 6
          },
          dailyWithdrawTimes: {
            type: "int32",
            id: 7
          },
          dailyWithdrawAmount: {
            type: "double",
            id: 8
          },
          upLevelGift: {
            type: "double",
            id: 9
          },
          birthdayGift: {
            type: "double",
            id: 10
          },
          redEnvelopeNeedWager: {
            type: "double",
            id: 11
          },
          redEnvelope: {
            type: "double",
            id: 12
          },
          liveCasino: {
            type: "double",
            id: 13
          },
          slotsGame: {
            type: "double",
            id: 14
          },
          chessGame: {
            type: "double",
            id: 15
          },
          sport: {
            type: "double",
            id: 16
          },
          lottery: {
            type: "double",
            id: 17
          },
          eSport: {
            type: "double",
            id: 18
          },
          fishing: {
            type: "double",
            id: 19
          }
        }
      },
      BannerInfo: {
        fields: {
          bannerId: {
            type: "int32",
            id: 1
          },
          bannerName: {
            type: "string",
            id: 2
          },
          showSeq: {
            type: "int32",
            id: 3
          },
          jumpType: {
            type: "int32",
            id: 4
          },
          url: {
            type: "string",
            id: 5
          },
          title: {
            type: "string",
            id: 6
          },
          subtitle: {
            type: "string",
            id: 7
          },
          fileUrl: {
            type: "string",
            id: 8
          },
          startTime: {
            type: "int64",
            id: 9
          },
          endTime: {
            type: "int64",
            id: 10
          },
          status: {
            type: "bool",
            id: 11
          },
          bannerIndex: {
            type: "int32",
            id: 12
          }
        }
      },
      ExchangeRateInfo: {
        fields: {
          currencyId: {
            type: "int32",
            id: 1
          },
          ExchangeRate: {
            type: "double",
            id: 2
          },
          exchangeRateValue: {
            type: "double",
            id: 3
          },
          exchangeRateValueOpen: {
            type: "bool",
            id: 4
          },
          fixedExchangeRate: {
            type: "double",
            id: 5
          },
          fixedExchangeRateOpen: {
            type: "bool",
            id: 6
          }
        }
      },
      BulletinInfo: {
        fields: {
          title: {
            type: "string",
            id: 1
          },
          jumpType: {
            type: "int32",
            id: 2
          },
          url: {
            type: "string",
            id: 3
          },
          fileUrl: {
            type: "string",
            id: 4
          },
          content: {
            type: "string",
            id: 5
          },
          startTime: {
            type: "int64",
            id: 6
          },
          endTime: {
            type: "int64",
            id: 7
          },
          status: {
            type: "bool",
            id: 8
          }
        }
      },
      PopupInfo: {
        fields: {
          name: {
            type: "string",
            id: 1
          },
          triggerType: {
            type: "int32",
            id: 2
          },
          jumpType: {
            type: "int32",
            id: 3
          },
          url: {
            type: "string",
            id: 4
          },
          fileUrl: {
            type: "string",
            id: 5
          },
          content: {
            type: "string",
            id: 6
          },
          startTime: {
            type: "int64",
            id: 7
          },
          endTime: {
            type: "int64",
            id: 8
          },
          status: {
            type: "bool",
            id: 9
          }
        }
      },
      Articles: {
        fields: {
          title: {
            type: "string",
            id: 1
          },
          enName: {
            type: "string",
            id: 2
          },
          categoryName: {
            type: "string",
            id: 3
          },
          content: {
            type: "string",
            id: 4
          },
          enContent: {
            type: "string",
            id: 5
          },
          sort: {
            type: "int32",
            id: 6
          },
          icon: {
            type: "string",
            id: 7
          }
        }
      },
      CustomerService: {
        fields: {
          name: {
            type: "string",
            id: 1
          },
          icon: {
            type: "string",
            id: 2
          },
          links: {
            type: "string",
            id: 3
          },
          showIndex: {
            type: "int32",
            id: 4
          },
          contactDetails: {
            type: "string",
            id: 5
          }
        }
      },
      ReqDepositDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResDepositDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          depositList: {
            rule: "repeated",
            type: "DepositInfo",
            id: 3
          }
        }
      },
      DepositInfo: {
        fields: {
          currencyId: {
            type: "int32",
            id: 1
          },
          paymentMethod: {
            type: "int32",
            id: 2
          },
          paymentMethodName: {
            type: "string",
            id: 3
          },
          network: {
            rule: "repeated",
            type: "string",
            id: 4
          },
          lowerLimit: {
            type: "int32",
            id: 5
          },
          upperLimit: {
            type: "int32",
            id: 6
          },
          paymentIcon: {
            type: "string",
            id: 7
          },
          rechargeAmount: {
            rule: "repeated",
            type: "RechargeAmount",
            id: 8
          }
        }
      },
      RechargeAmount: {
        fields: {
          amount: {
            type: "int32",
            id: 1
          },
          extra: {
            type: "double",
            id: 2
          },
          type: {
            type: "int32",
            id: 3
          }
        }
      },
      ReqWithdrawDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResWithdrawDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          drawStandard: {
            type: "double",
            id: 3
          },
          bettingVolume: {
            type: "double",
            id: 4
          },
          withdrawList: {
            rule: "repeated",
            type: "WithdrawInfo",
            id: 5
          }
        }
      },
      WithdrawInfo: {
        fields: {
          currencyId: {
            type: "int32",
            id: 1
          },
          paymentMethod: {
            type: "int32",
            id: 2
          },
          paymentMethodName: {
            type: "string",
            id: 3
          },
          paymentIcon: {
            type: "string",
            id: 4
          },
          network: {
            rule: "repeated",
            type: "string",
            id: 5
          },
          fee: {
            type: "double",
            id: 6
          },
          lowerLimit: {
            type: "int32",
            id: 7
          },
          upperLimit: {
            type: "int32",
            id: 8
          },
          withdrawAccount: {
            rule: "repeated",
            type: "WithdrawAccount",
            id: 9
          }
        }
      },
      ReqCreateRechargeOrderMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          currencyId: {
            type: "int32",
            id: 2
          },
          paymentMethod: {
            type: "int32",
            id: 3
          },
          amounts: {
            type: "double",
            id: 4
          },
          walletAddress: {
            type: "string",
            id: 5
          }
        }
      },
      ResCreateRechargeOrderMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          payAddress: {
            type: "string",
            id: 3
          }
        }
      },
      ReqCreateWithdrawOrderMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          currencyId: {
            type: "int32",
            id: 2
          },
          paymentMethod: {
            type: "int32",
            id: 3
          },
          withdrawAccountId: {
            type: "string",
            id: 4
          },
          amount: {
            type: "double",
            id: 5
          },
          drawPassword: {
            type: "string",
            id: 6
          },
          fee: {
            type: "double",
            id: 7
          },
          balance: {
            type: "double",
            id: 8
          }
        }
      },
      ResCreateWithdrawOrderMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqAddDeleteWithdrawAccountMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          type: {
            type: "int32",
            id: 2
          },
          currencyId: {
            type: "int32",
            id: 3
          },
          paymentMethod: {
            type: "int32",
            id: 4
          },
          network: {
            type: "string",
            id: 5
          },
          withdrawAccountId: {
            type: "string",
            id: 6
          },
          withdrawAccount: {
            type: "WithdrawAccount",
            id: 7
          }
        }
      },
      ResAddDeleteWithdrawAccountMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          type: {
            type: "int32",
            id: 3
          },
          withdrawAccountId: {
            type: "string",
            id: 4
          },
          withdrawAccount: {
            type: "WithdrawAccount",
            id: 5
          }
        }
      },
      WithdrawAccount: {
        fields: {
          withdrawAccountId: {
            type: "string",
            id: 1
          },
          extend_0: {
            type: "string",
            id: 2
          },
          extend_1: {
            type: "string",
            id: 3
          },
          extend_2: {
            type: "string",
            id: 4
          },
          extend_3: {
            type: "string",
            id: 5
          }
        }
      },
      DCurrencyItem: {
        fields: {
          currencyId: {
            type: "int32",
            id: 1
          },
          value: {
            type: "double",
            id: 2
          }
        }
      },
      DItemShow: {
        fields: {
          itemId: {
            type: "int32",
            id: 1
          },
          num: {
            type: "double",
            id: 2
          }
        }
      },
      PlayerInfo: {
        fields: {
          playerId: {
            type: "int64",
            id: 1
          },
          playerName: {
            type: "string",
            id: 2
          },
          headId: {
            type: "string",
            id: 3
          },
          createTime: {
            type: "int64",
            id: 4
          },
          cItem: {
            rule: "repeated",
            type: "DCurrencyItem",
            id: 5
          },
          currencyId: {
            type: "int32",
            id: 6
          },
          superiorId: {
            type: "string",
            id: 7
          },
          gameId: {
            type: "int32",
            id: 8
          },
          accountBind: {
            rule: "repeated",
            type: "int32",
            id: 9
          },
          email: {
            type: "string",
            id: 10
          },
          vipLevel: {
            type: "int32",
            id: 11
          },
          curWagered: {
            type: "double",
            id: 12
          },
          curRecharge: {
            type: "double",
            id: 13
          },
          relegationWagered: {
            type: "double",
            id: 14
          },
          relegationTime: {
            type: "int64",
            id: 15
          },
          referralLink: {
            type: "string",
            id: 16
          },
          referralCode: {
            type: "string",
            id: 17
          },
          realName: {
            type: "string",
            id: 18
          },
          birthday: {
            type: "int64",
            id: 19
          },
          phone: {
            type: "string",
            id: 20
          },
          address: {
            type: "string",
            id: 21
          },
          agentId: {
            type: "int64",
            id: 22
          },
          favoritesGames: {
            rule: "repeated",
            type: "FavoritesGame",
            id: 23
          },
          kycAuth: {
            type: "int32",
            id: 24
          }
        }
      },
      FavoritesGame: {
        fields: {
          platformId: {
            type: "int32",
            id: 1
          },
          gameId: {
            rule: "repeated",
            type: "int32",
            id: 2
          }
        }
      },
      InboxInfo: {
        fields: {
          inboxId: {
            type: "string",
            id: 1
          },
          inboxType: {
            type: "int32",
            id: 2
          },
          title: {
            type: "string",
            id: 3
          },
          context: {
            type: "string",
            id: 4
          },
          expireTime: {
            type: "int64",
            id: 5
          },
          read: {
            type: "bool",
            id: 6
          },
          createTime: {
            type: "int64",
            id: 7
          },
          extend: {
            type: "string",
            id: 8
          }
        }
      },
      GameApiInfo: {
        fields: {
          platformId: {
            type: "int32",
            id: 1
          },
          gameId: {
            type: "int32",
            id: 2
          },
          platformGameId: {
            type: "string",
            id: 3
          },
          gameProvider: {
            type: "string",
            id: 4
          },
          gameName: {
            type: "string",
            id: 5
          },
          gameType: {
            type: "int32",
            id: 6
          },
          seq: {
            type: "int32",
            id: 7
          },
          subType: {
            type: "int32",
            id: 8
          },
          tag: {
            type: "int32",
            id: 9
          },
          fileUrl: {
            type: "string",
            id: 10
          },
          status: {
            type: "int32",
            id: 11
          }
        }
      },
      BetInfo: {
        fields: {
          platformId: {
            type: "int32",
            id: 1
          },
          gameName: {
            type: "string",
            id: 2
          },
          noteId: {
            type: "string",
            id: 3
          },
          betTime: {
            type: "int64",
            id: 4
          },
          betAmount: {
            type: "double",
            id: 5
          },
          validBets: {
            type: "double",
            id: 6
          },
          win: {
            type: "double",
            id: 7
          },
          payout: {
            type: "double",
            id: 8
          },
          status: {
            type: "int32",
            id: 9
          }
        }
      },
      ReqPlayerEntryHallMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          account: {
            type: "string",
            id: 2
          },
          accountId: {
            type: "int64",
            id: 3
          },
          ip: {
            type: "string",
            id: 4
          },
          gateId: {
            type: "int32",
            id: 5
          },
          threeParty: {
            type: "int32",
            id: 6
          },
          device: {
            type: "string",
            id: 7
          }
        }
      },
      ResPlayerEntryHallMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqRefreshPlayerDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResRefreshPlayerDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          playerInfo: {
            type: "PlayerInfo",
            id: 3
          }
        }
      },
      ReqPlayerSignOutMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResPlayerSignOutMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqHeadChangeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          headId: {
            type: "int32",
            id: 2
          }
        }
      },
      ResHeadChangeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          headId: {
            type: "int32",
            id: 3
          }
        }
      },
      ReqVerifyEmailMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          email: {
            type: "string",
            id: 2
          },
          verifyCode: {
            type: "string",
            id: 3
          }
        }
      },
      ResVerifyEmailMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          email: {
            type: "string",
            id: 3
          }
        }
      },
      ReqResetPasswordMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          type: {
            type: "int32",
            id: 2
          },
          oldPassword: {
            type: "string",
            id: 3
          },
          password: {
            type: "string",
            id: 4
          },
          confirmPassWord: {
            type: "string",
            id: 5
          }
        }
      },
      ResResetPasswordMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          type: {
            type: "int32",
            id: 3
          }
        }
      },
      ReqNameModifyMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          userName: {
            type: "string",
            id: 2
          },
          birthday: {
            type: "int64",
            id: 3
          },
          email: {
            type: "string",
            id: 4
          },
          phone: {
            type: "string",
            id: 5
          },
          address: {
            type: "string",
            id: 6
          }
        }
      },
      ResNameModifyMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqGameChannelsDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResGameChannelsDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          gameChannelsList: {
            rule: "repeated",
            type: "GameChannelsInfo",
            id: 3
          }
        }
      },
      GameChannelsInfo: {
        fields: {
          channels: {
            type: "int32",
            id: 1
          },
          channelsName: {
            type: "string",
            id: 2
          },
          sort: {
            type: "int32",
            id: 3
          },
          platformList: {
            rule: "repeated",
            type: "PlatformInfo",
            id: 4
          }
        }
      },
      PlatformInfo: {
        fields: {
          platformId: {
            type: "int32",
            id: 1
          },
          platformName: {
            type: "string",
            id: 2
          },
          gameProvider: {
            type: "string",
            id: 3
          },
          fileUrl: {
            type: "string",
            id: 4
          },
          icon: {
            type: "string",
            id: 5
          },
          clickIcon: {
            type: "string",
            id: 6
          },
          showGameList: {
            type: "bool",
            id: 7
          },
          gameApiInfo: {
            rule: "repeated",
            type: "GameApiInfo",
            id: 8
          }
        }
      },
      ReqGameDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          channels: {
            type: "int32",
            id: 2
          },
          platformId: {
            type: "int32",
            id: 3
          },
          gameTag: {
            type: "int32",
            id: 4
          },
          page: {
            type: "int32",
            id: 5
          },
          pageSize: {
            type: "int32",
            id: 6
          },
          gameName: {
            type: "string",
            id: 7
          }
        }
      },
      ResGameDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          page: {
            type: "int32",
            id: 3
          },
          pageSize: {
            type: "int32",
            id: 4
          },
          total: {
            type: "int32",
            id: 5
          },
          totalPage: {
            type: "int32",
            id: 6
          },
          gameApiList: {
            rule: "repeated",
            type: "GameApiInfo",
            id: 7
          }
        }
      },
      ReqFavoritesGameMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          gameId: {
            type: "int32",
            id: 2
          },
          platformId: {
            type: "int32",
            id: 3
          }
        }
      },
      ResFavoritesGameMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          gameId: {
            type: "int32",
            id: 3
          }
        }
      },
      ReqEntryAgentGameMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          gameId: {
            type: "int32",
            id: 2
          },
          currencyId: {
            type: "int32",
            id: 3
          },
          currencyName: {
            type: "string",
            id: 4
          },
          platformId: {
            type: "int32",
            id: 5
          },
          gameType: {
            type: "int32",
            id: 6
          },
          platformGameId: {
            type: "string",
            id: 7
          }
        }
      },
      ResEntryAgentGameMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          urlType: {
            type: "int32",
            id: 3
          },
          gameUrl: {
            type: "string",
            id: 4
          },
          gameId: {
            type: "int32",
            id: 5
          }
        }
      },
      ReqSendPhoneVerificationCodeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          areaCode: {
            type: "string",
            id: 2
          },
          phone: {
            type: "string",
            id: 3
          }
        }
      },
      ResSendPhoneVerificationCodeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqBindPhoneMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          areaCode: {
            type: "string",
            id: 2
          },
          phone: {
            type: "string",
            id: 3
          },
          verificationCode: {
            type: "string",
            id: 4
          }
        }
      },
      ResBindPhoneMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqApplyAgentMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          name: {
            type: "string",
            id: 2
          },
          phone: {
            type: "string",
            id: 3
          },
          desc: {
            type: "string",
            id: 4
          }
        }
      },
      ResApplyAgentMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqKycAuthMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          name: {
            type: "string",
            id: 2
          },
          documentType: {
            type: "int32",
            id: 3
          },
          IDNumber: {
            type: "string",
            id: 4
          },
          front: {
            type: "string",
            id: 5
          },
          back: {
            type: "string",
            id: 6
          },
          photo: {
            type: "string",
            id: 7
          }
        }
      },
      ResKycAuthMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqInboxDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResInboxDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          inboxList: {
            rule: "repeated",
            type: "InboxInfo",
            id: 3
          }
        }
      },
      ReqReadInBoxMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          inboxId: {
            type: "string",
            id: 2
          }
        }
      },
      ResReadInBoxMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          inboxId: {
            type: "string",
            id: 3
          }
        }
      },
      ReqAllReadInBoxMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResAllReadInBoxMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          inboxId: {
            rule: "repeated",
            type: "string",
            id: 3
          }
        }
      },
      ReqDeleteInboxMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          inboxId: {
            type: "string",
            id: 2
          }
        }
      },
      ResDeleteInboxMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          inboxId: {
            type: "string",
            id: 3
          }
        }
      },
      ReqOneKeyDeleteReadInboxMessage: {
        oneofs: {
          _msgID: {
            oneof: [
              "msgID"
            ]
          }
        },
        fields: {
          msgID: {
            type: "int32",
            id: 1,
            options: {
              proto3_optional: true
            }
          }
        }
      },
      ResOneKeyDeleteReadInboxMessage: {
        oneofs: {
          _msgID: {
            oneof: [
              "msgID"
            ]
          }
        },
        fields: {
          msgID: {
            type: "int32",
            id: 1,
            options: {
              proto3_optional: true
            }
          },
          error: {
            type: "int32",
            id: 2
          },
          inboxId: {
            rule: "repeated",
            type: "string",
            id: 3
          }
        }
      },
      InnerReqServerListMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          type: {
            rule: "repeated",
            type: "int32",
            id: 2
          }
        }
      },
      InnerResServerListMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          serverList: {
            rule: "repeated",
            type: "ServerInfoList",
            id: 2
          }
        }
      },
      ServerInfoList: {
        fields: {
          type: {
            type: "int32",
            id: 1
          },
          serverInfos: {
            rule: "repeated",
            type: "InnerServerInfo",
            id: 2
          }
        }
      },
      InnerServerInfo: {
        fields: {
          id: {
            type: "int32",
            id: 1
          },
          ip: {
            type: "string",
            id: 2
          },
          type: {
            type: "int32",
            id: 3
          },
          port: {
            type: "int32",
            id: 4
          },
          gameState: {
            type: "int32",
            id: 5
          },
          version: {
            type: "string",
            id: 6
          },
          content: {
            type: "string",
            id: 7
          },
          power: {
            type: "int32",
            id: 8
          },
          online: {
            type: "int32",
            id: 9
          },
          httpPort: {
            type: "int32",
            id: 10
          },
          name: {
            type: "string",
            id: 11
          },
          wwwIp: {
            type: "string",
            id: 12
          },
          ipWhiteList: {
            type: "string",
            id: 13
          },
          accountWhiteList: {
            type: "string",
            id: 14
          }
        }
      },
      InnerReqRegisterUpdateMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          serverInfo: {
            type: "InnerServerInfo",
            id: 2
          }
        }
      },
      InnerReqLoginOutHallMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          playerID: {
            type: "int64",
            id: 2
          }
        }
      },
      InnerReqHttpHandlerMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          params: {
            type: "string",
            id: 2
          },
          requestPath: {
            type: "string",
            id: 3
          },
          playerID: {
            type: "int64",
            id: 4
          }
        }
      },
      InnerResHttpHandlerMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          params: {
            type: "string",
            id: 2
          },
          requestPath: {
            type: "string",
            id: 3
          }
        }
      },
      InnerBroadcastMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          playerGuid: {
            rule: "repeated",
            type: "int64",
            id: 2
          },
          languageId: {
            type: "string",
            id: 3
          },
          params: {
            rule: "repeated",
            type: "string",
            id: 4
          }
        }
      },
      InnerNotifyRechargeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          currencyId: {
            type: "int32",
            id: 2
          },
          amount: {
            type: "double",
            id: 3
          },
          orderId: {
            type: "int64",
            id: 4
          }
        }
      },
      InnerNotifyWithdrawMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          currencyId: {
            type: "int32",
            id: 2
          },
          amount: {
            type: "double",
            id: 3
          },
          orderId: {
            type: "int64",
            id: 4
          },
          status: {
            type: "int32",
            id: 5
          }
        }
      },
      InnerNotifyCurrencyUpdateMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          platformId: {
            type: "int32",
            id: 3
          },
          gameId: {
            type: "int32",
            id: 4
          },
          gameType: {
            type: "int32",
            id: 5
          },
          agentId: {
            type: "int64",
            id: 6
          },
          type: {
            type: "int32",
            id: 7
          },
          betAmount: {
            type: "double",
            id: 8
          },
          validBet: {
            type: "double",
            id: 9
          },
          win: {
            type: "double",
            id: 10
          },
          balance: {
            type: "double",
            id: 11
          },
          data: {
            type: "string",
            id: 12
          }
        }
      },
      InnerNotifyLogoutAgentGameMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          playerId: {
            type: "int64",
            id: 2
          }
        }
      },
      ReqRegisterMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          account: {
            type: "string",
            id: 2
          },
          password: {
            type: "string",
            id: 3
          },
          confirmPassword: {
            type: "string",
            id: 4
          },
          realName: {
            type: "string",
            id: 5
          },
          drawPassword: {
            type: "string",
            id: 6
          },
          referralCode: {
            type: "string",
            id: 7
          },
          al: {
            type: "string",
            id: 8
          },
          device: {
            type: "string",
            id: 9
          }
        }
      },
      ReqLoginMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          account: {
            type: "string",
            id: 2
          },
          password: {
            type: "string",
            id: 3
          },
          threeParty: {
            type: "int32",
            id: 4
          },
          referralCode: {
            type: "string",
            id: 5
          },
          al: {
            type: "string",
            id: 6
          },
          device: {
            type: "string",
            id: 7
          }
        }
      },
      ResLoginMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          account: {
            type: "string",
            id: 3
          },
          gateAddress: {
            type: "string",
            id: 4
          },
          token: {
            type: "string",
            id: 5
          },
          threeParty: {
            type: "int32",
            id: 6
          }
        }
      },
      ReqMailVerifyCodeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          email: {
            type: "string",
            id: 2
          },
          codeType: {
            type: "int32",
            id: 3
          }
        }
      },
      ResMailVerifyCodeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          email: {
            type: "string",
            id: 3
          }
        }
      },
      ReqNotLoggedInMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          }
        }
      },
      ResNotLoggedInMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          gateAddress: {
            type: "string",
            id: 3
          },
          token: {
            type: "string",
            id: 4
          }
        }
      },
      MID: {
        values: {
          none: 0,
          InnerReqRegisterUpdate: 100001,
          InnerReqHttpHandler: 100002,
          InnerResHttpHandler: 100003,
          InnerBroadcast: 100004,
          InnerReqServerList: 100005,
          InnerResServerList: 100006,
          InnerReqLoginOutHall: 100007,
          InnerNotifyRecharge: 100008,
          InnerNotifyWithdraw: 100009,
          InnerNotifyCurrencyUpdate: 100010,
          InnerNotifyLogoutAgentGame: 100011,
          InnerNotifyGameNote: 100012,
          ReqRegister: 200001,
          ReqLogin: 200002,
          ResLogin: 200003,
          ReqMailVerifyCode: 200004,
          ResMailVerifyCode: 200005,
          ReqNotLoggedIn: 200006,
          ResNotLoggedIn: 200007,
          ReqPlayerEntryHall: 400001,
          ResPlayerEntryHall: 400002,
          ReqPlayerSignOut: 400003,
          ResPlayerSignOut: 400004,
          ReqHeadChange: 400005,
          ResHeadChange: 400006,
          ReqVerifyEmail: 400007,
          ResVerifyEmail: 400008,
          ReqResetPassword: 400009,
          ResResetPassword: 400010,
          ReqNameModify: 400011,
          ResNameModify: 400012,
          ReqInboxData: 400013,
          ResInboxData: 400014,
          ReqReadInBox: 400015,
          ResReadInBox: 400016,
          ReqDeleteInbox: 400017,
          ResDeleteInbox: 400018,
          ReqGameData: 400019,
          ResGameData: 400020,
          ReqGameChannelsData: 400021,
          ResGameChannelsData: 400022,
          ReqEntryAgentGame: 400023,
          ResEntryAgentGame: 400024,
          ReqSendPhoneVerificationCode: 400025,
          ResSendPhoneVerificationCode: 400026,
          ReqBindPhone: 400027,
          ResBindPhone: 400028,
          ReqApplyAgent: 400029,
          ResApplyAgent: 400030,
          ReqFavoritesGame: 400031,
          ResFavoritesGame: 400032,
          ReqRefreshPlayerData: 400033,
          ResRefreshPlayerData: 400034,
          ReqOneKeyDeleteReadInbox: 400035,
          ResOneKeyDeleteReadInbox: 400036,
          ReqAllReadInBox: 400037,
          ResAllReadInBox: 400038,
          ReqKycAuth: 400039,
          ResKycAuth: 400040,
          ReqBetHistoryData: 400201,
          ResBetHistoryData: 400202,
          ReqTransactionData: 400203,
          ResTransactionData: 400204,
          ReqDepositData: 400301,
          ResDepositData: 400302,
          ReqWithdrawData: 400303,
          ResWithdrawData: 400304,
          ReqCreateRechargeOrder: 400305,
          ResCreateRechargeOrder: 400306,
          ReqCreateWithdrawOrder: 400307,
          ResCreateWithdrawOrder: 400308,
          ReqAddDeleteWithdrawAccount: 400309,
          ResAddDeleteWithdrawAccount: 400310,
          ReqBindSuperior: 400401,
          ResBindSuperior: 400402,
          ReqCommissionRewardData: 400403,
          ResCommissionRewardData: 400404,
          ReqConfigData: 400501,
          ResConfigData: 400502,
          ReqActivityData: 400601,
          ResActivityData: 400602,
          ReqWelfareData: 400603,
          ResWelfareData: 400604,
          ReqReceiveWelfare: 400605,
          ResReceiveWelfare: 400606,
          ReqApplyActivity: 400607,
          ResApplyActivity: 400608,
          ReqActivityApplyData: 400609,
          ResActivityApplyData: 400610,
          ReqTcpTokenAuth: 450001,
          ResTcpTokenAuth: 450002,
          ReqTcpHeartBeat: 450003,
          ResTcpHeartBeat: 450004,
          ResTcpSysError: 450005,
          ResTcpCurrencyUpdate: 450006,
          ResTcpReceiveInbox: 450007,
          ResTcpVipClubExpChange: 450008,
          ResTcpBulletinData: 450009
        }
      },
      ReqTcpTokenAuthMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          token: {
            type: "string",
            id: 2
          }
        }
      },
      ResTcpTokenAuthMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          }
        }
      },
      ReqTcpHeartBeatMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          gameName: {
            type: "string",
            id: 2
          },
          account: {
            type: "string",
            id: 3
          },
          businessNo: {
            type: "string",
            id: 4
          }
        }
      },
      ResTcpHeartBeatMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          systemTime: {
            type: "int64",
            id: 3
          }
        }
      },
      ResTcpSysErrorMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          context: {
            type: "string",
            id: 3
          }
        }
      },
      ResTcpCurrencyUpdateMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          cItem: {
            rule: "repeated",
            type: "DCurrencyItem",
            id: 2
          }
        }
      },
      ResTcpReceiveInboxMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          inboxList: {
            rule: "repeated",
            type: "InboxInfo",
            id: 2
          }
        }
      },
      ResTcpVipClubExpChangeMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          vipLevel: {
            type: "int32",
            id: 2
          },
          curWagered: {
            type: "double",
            id: 3
          },
          curRecharge: {
            type: "double",
            id: 4
          },
          relegationWagered: {
            type: "double",
            id: 5
          }
        }
      },
      ResTcpBulletinDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          bulletinInfo: {
            rule: "repeated",
            type: "BulletinInfo",
            id: 3
          }
        }
      },
      ReqBetHistoryDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          platformId: {
            type: "int32",
            id: 2
          },
          gameType: {
            type: "int32",
            id: 3
          },
          past: {
            type: "int32",
            id: 4
          },
          page: {
            type: "int32",
            id: 5
          },
          pageSize: {
            type: "int32",
            id: 6
          },
          statTime: {
            type: "int64",
            id: 7
          },
          endTime: {
            type: "int64",
            id: 8
          }
        }
      },
      ResBetHistoryDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          platformId: {
            type: "int32",
            id: 3
          },
          gameType: {
            type: "int32",
            id: 4
          },
          past: {
            type: "int32",
            id: 5
          },
          page: {
            type: "int32",
            id: 6
          },
          pageSize: {
            type: "int32",
            id: 7
          },
          total: {
            type: "int32",
            id: 8
          },
          totalPage: {
            type: "int32",
            id: 9
          },
          betList: {
            rule: "repeated",
            type: "BetInfo",
            id: 10
          }
        }
      },
      ReqTransactionDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          transactionType: {
            type: "int32",
            id: 2
          },
          past: {
            type: "int32",
            id: 3
          },
          page: {
            type: "int32",
            id: 4
          },
          pageSize: {
            type: "int32",
            id: 5
          },
          statTime: {
            type: "int64",
            id: 6
          },
          endTime: {
            type: "int64",
            id: 7
          }
        }
      },
      ResTransactionDataMessage: {
        fields: {
          msgID: {
            type: "int32",
            id: 1
          },
          error: {
            type: "int32",
            id: 2
          },
          transactionType: {
            type: "int32",
            id: 3
          },
          past: {
            type: "int32",
            id: 4
          },
          page: {
            type: "int32",
            id: 5
          },
          pageSize: {
            type: "int32",
            id: 6
          },
          total: {
            type: "int32",
            id: 7
          },
          totalPage: {
            type: "int32",
            id: 8
          },
          transactionList: {
            rule: "repeated",
            type: "TransactionInfo",
            id: 9
          }
        }
      },
      TransactionInfo: {
        fields: {
          time: {
            type: "int64",
            id: 1
          },
          orderId: {
            type: "string",
            id: 2
          },
          type: {
            type: "int32",
            id: 3
          },
          currencyId: {
            type: "int32",
            id: 4
          },
          amount: {
            type: "double",
            id: 5
          },
          status: {
            type: "int32",
            id: 6
          }
        }
      }
    }
  }
});

export { $root as default };
