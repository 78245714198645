import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

//请求加密货币数据
export async function getCryptoData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqDepositWithdrawDataMessage', data, 'ReqDepositWithdrawData'),
            id: 400301
        }, {
            transformResponse: [getResponseData('ResDepositWithdrawDataMessage')]
        })
    }
}

//请求充值数据
export async function getDepositData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqDepositDataMessage', data, 'ReqDepositData'),
            id: 400301
        }, {
            transformResponse: [getResponseData('ResDepositDataMessage')]
        })
    }
}

//请求提现数据
export async function getWithdrawData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqWithdrawDataMessage', data, 'ReqWithdrawData'),
            id: 400303
        }, {
            transformResponse: [getResponseData('ResWithdrawDataMessage')]
        })
    }
    
}

// 创建充值订单
export async function createRechargeOrder(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqCreateRechargeOrderMessage', data, 'ReqCreateRechargeOrder'),
            id: 400305
        }, {
            transformResponse: [getResponseData('ResCreateRechargeOrderMessage')]
        })
    }
}

// 创建提现订单
export async function createWithdrawOrder(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqCreateWithdrawOrderMessage', data, 'ReqCreateWithdrawOrder'),
            id: 400307
        }, {
            transformResponse: [getResponseData('ResCreateWithdrawOrderMessage')]
        })
    }
}

//请求添加删除提现账户
export async function addDeleteWithdrawAccount(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqAddDeleteWithdrawAccountMessage', data, 'ReqAddDeleteWithdrawAccount'),
            id: 400309
        }, {
            transformResponse: [getResponseData('ResAddDeleteWithdrawAccountMessage')]
        })
    }
}

//请求支付方式管理数据
export async function getPaymentMethodsData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqPaymentMethodsDataMessage', data, 'ReqPaymentMethodsData'),
            id: 400057
        }, {
            transformResponse: [getResponseData('ResPaymentMethodsDataMessage')]
        })
    }
}
