import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

//请求绑定上级
export async function bindSuperior(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqBindSuperiorMessage', data, 'ReqBindSuperior'),
            id: 400401
        }, {
            transformResponse: [getResponseData('ResBindSuperiorMessage')]
        })
    }
}

//请求仪表板数据
export async function getDashboardData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqDashboardDataMessage', data, 'ReqDashboardData'),
            id: 400403
        }, {
            transformResponse: [getResponseData('ResDashboardDataMessage')]
        })
    }
}

//请求我的奖励
export async function getMyRewardData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqMyRewardDataMessage', data, 'ReqMyRewardData'),
            id: 400405
        }, {
            transformResponse: [getResponseData('ResMyRewardDataMessage')]
        })
    }
}

//请求佣金奖励
export async function getCommissionRewardData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqCommissionRewardDataMessage', data, 'ReqCommissionRewardData'),
            id: 400403
        }, {
            transformResponse: [getResponseData('ResCommissionRewardDataMessage')]
        })
    }
}

//请求推广奖励
export async function getReferralRewardData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqReferralRewardDataMessage', data, 'ReqReferralRewardData'),
            id: 400409
        }, {
            transformResponse: [getResponseData('ResReferralRewardDataMessage')]
        })
    }
}

//请求推广码和下级数据
export async function getReferralRewardSearchData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqReferralCodeAndFriendsDataMessage', data, 'ReqReferralCodeAndFriendsData'),
            id: 400411
        }, {
            transformResponse: [getResponseData('ResReferralCodeAndFriendsDataMessage')]
        })
    }
}

//请求创建推广码
export async function createReferralCode(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqCreateReferralCodeMessage', data, 'ReqCreateReferralCode'),
            id: 400413
        }, {
            transformResponse: [getResponseData('ResCreateReferralCodeMessage')]
        })
    }
}


//请求推广码数据
export async function getReferralCodeData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqReferralCodeDataMessage', data, 'ReqReferralCodeData'),
            id: 400415
        }, {
            transformResponse: [getResponseData('ResReferralCodeDataMessage')]
        })
    }
}

//请求下级数据
export async function getFriendsData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqFriendsDataMessage', data, 'ReqFriendsData'),
            id: 400417
        }, {
            transformResponse: [getResponseData('ResFriendsDataMessage')]
        })
    }
}

//请求提现到钱包
export async function requestWithdrawToWallet(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqWithdrawToWalletMessage', data, 'ReqWithdrawToWallet'),
            id: 400405
        }, {
            transformResponse: [getResponseData('ResWithdrawToWalletMessage')]
        })
    }
}

//请求历史
export async function getHistoryData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqHistoryDataMessage', data, 'ReqHistoryData'),
            id: 400421
        }, {
            transformResponse: [getResponseData('ResHistoryDataMessage')]
        })
    }
}

//请求提现数据
export async function getAffiliateWithdrawData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqAffiliateWithdrawDataMessage', data, 'ReqAffiliateWithdrawData'),
            id: 400423
        }, {
            transformResponse: [getResponseData('ResAffiliateWithdrawDataMessage')]
        })
    }
}


//请求团长奖励
export async function getTeamRewardData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqTeamRewardDataMessage', data, 'ReqTeamRewardData'),
            id: 400425
        }, {
            transformResponse: [getResponseData('ResTeamRewardDataMessage')]
        })
    }
}
