import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

// 进入大厅
export async function entryHall(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqPlayerEntryHallMessage', data, 'ReqPlayerEntryHall'),
            id: 400001
        }, {
            transformResponse: [getResponseData('ResPlayerEntryHallMessage')]
        })
    }
}

export async function refreshPlayerData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqRefreshPlayerDataMessage', data, 'ReqRefreshPlayerData'),
            id: 400033
        }, {
            transformResponse: [getResponseData('ResRefreshPlayerDataMessage')]
        })
    }
    
}

// 退出登陆
export async function logOut(data) {
    const host = getHallHost()
    if (host) {
        return axios.post('https://' + host +'/_api/graphql',{
            data: getRquestData('ReqPlayerSignOutMessage', data, 'ReqPlayerSignOut'),
            id: 400003
        }, {
            transformResponse: [getResponseData('ResPlayerSignOutMessage')]
        })
    }
    
}

// 修改用户名
export async function modifyName(data) {
    const host = getHallHost()
    if (host) {
        return axios.post('https://' + host +'/_api/graphql',{
            data: getRquestData('ReqNameModifyMessage', data, 'ReqNameModify'),
            id: 400011
        }, {
            transformResponse: [getResponseData('ResNameModifyMessage')]
        })
    }
    
}

// 请求用户数据
export async function getAccountData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post('https://' + host +'/_api/graphql',{
            data: getRquestData('ReqAccountDataMessage', data, 'ReqAccountData'),
            id: 400023
        }, {
            transformResponse: [getResponseData('ResAccountDataMessage')]
        })
    }
    
}

// 请求验证邮箱
export async function verifyEmail(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqVerifyEmailMessage', data, 'ReqVerifyEmail'),
            id: 400007
        }, {
            transformResponse: [getResponseData('ResVerifyEmailMessage')]
        })
    }
}

// 请求Enable2FA
export async function enable2FAData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqEnable2FADataMessage', data, 'ReqEnable2FAData'),
            id: 400033
        }, {
            transformResponse: [getResponseData('ResEnable2FADataMessage')]
        })
    }
}

// 请求2FA Verification Code
export async function get2FAVerificationCode(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('Req2FAVerificationCodeMessage', data, 'Req2FAVerificationCode'),
            id: 400035
        }, {
            transformResponse: [getResponseData('Res2FAVerificationCodeMessage')]
        })
    }
}

// // 请求注册绑定
// export async function registerBind(data) {
//     const host = getHallHost()
//     if (host) {
//         return axios.post( 'https://' + host +'/_api/graphql',{
//             data: getRquestData('ReqRegisterBindMessage', data, 'Req2FAVerificationCode'),
//             id: 400035
//         }, {
//             transformResponse: [getResponseData('ResRegisterBindMessage')]
//         })
//     }
// }

// 请求重置密码
export async function resetPassword(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqResetPasswordMessage', data, 'ReqResetPassword'),
            id: 400009
        }, {
            transformResponse: [getResponseData('ResResetPasswordMessage')]
        })
    }
}

// 请求切换货币
export async function changeCurrency(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqChangeCurrencyMessage', data, 'ReqChangeCurrency'),
            id: 400021
        }, {
            transformResponse: [getResponseData('ResChangeCurrencyMessage')]
        })
    }
}


// 请求显示法币
export async function viewInFiat(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqViewInFiatMessage', data, 'ReqViewInFiat'),
            id: 400023
        }, {
            transformResponse: [getResponseData('ResViewInFiatMessage')]
        })
    }
}

// 请求搜索游戏
export async function searchGameData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqSearchGameDataMessage', data, 'ReqSearchGameData'),
            id: 400031
        }, {
            transformResponse: [getResponseData('ResSearchGameDataMessage')]
        })
    }
}

// 请求头像更换
export async function headChange(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqHeadChangeMessage', data, 'ReqHeadChange'),
            id: 400005
        }, {
            transformResponse: [getResponseData('ResHeadChangeMessage')]
        })
    }
}

//请求进入三方游戏
export async function entryAgentGame(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqEntryAgentGameMessage', data, 'ReqEntryAgentGame'),
            id: 400023
        }, {
            transformResponse: [getResponseData('ResEntryAgentGameMessage')]
        })
    }
}

// //请求游戏记录
// export async function getGameNoteData(data) {
//     const host = getHallHost()
//     if (host) {
//         return axios.post( 'https://' + host +'/_api/graphql',{
//             data: getRquestData('ReqGameNoteDataMessage', data, 'ReqGameNoteData'),
//             id: 400039
//         }, {
//             transformResponse: [getResponseData('ResGameNoteDataMessage')]
//         })
//     }
// }

//请求收藏数据
export async function getFavoritesData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqFavoritesGameMessage', data, 'ReqFavoritesGame'),
            id: 400031
        }, {
            transformResponse: [getResponseData('ResFavoritesGameMessage')]
        })
    }
}

//请求最近数据
// export async function getRecentData(data) {
//     const host = getHallHost()
//     if (host) {
//         return axios.post( 'https://' + host +'/_api/graphql',{
//             data: getRquestData('ReqRecentDataMessage', data, 'ReqRecentData'),
//             id: 400043
//         }, {
//             transformResponse: [getResponseData('ResRecentDataMessage')]
//         })
//     }
// }

//请求收藏、点赞
export async function userInteraction(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqUserInteractionMessage', data, 'ReqUserInteraction'),
            id: 400039
        }, {
            transformResponse: [getResponseData('ResUserInteractionMessage')]
        })
    }
}

//请求游戏opt
export async function getGameOptData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqGameOptDataMessage', data, 'ReqGameOptData'),
            id: 400043
        }, {
            transformResponse: [getResponseData('ResGameOptDataMessage')]
        })
    }
}

//请求获取排行数据
export async function getGetRankData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqGetRankDataMessage', data, 'ReqGetRankData'),
            id: 400043
        }, {
            transformResponse: [getResponseData('ResGetRankDataMessage')]
        })
    }
}

//请求最近最大数据
export async function getRecentBigWinsData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqRecentBigWinsDataMessage', data, 'ReqRecentBigWinsData'),
            id: 400045
        }, {
            transformResponse: [getResponseData('ResRecentBigWinsDataMessage')]
        })
    }
}



//请求发送手机验证码
export async function sendPhoneVerificationCode(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqSendPhoneVerificationCodeMessage', data, 'ReqSendPhoneVerificationCode'),
            id: 400047
        }, {
            transformResponse: [getResponseData('ResSendPhoneVerificationCodeMessage')]
        })
    }
}

//请求绑定手机
export async function bindPhone(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqBindPhoneMessage', data, 'ReqBindPhone'),
            id: 400049
        }, {
            transformResponse: [getResponseData('ResBindPhoneMessage')]
        })
    }
}

//请求解绑三方
export async function unbindThreeParty(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqUnbindThreePartyMessage', data, 'ReqUnbindThreeParty'),
            id: 400051
        }, {
            transformResponse: [getResponseData('ResUnbindThreePartyMessage')]
        })
    }
}

//请求绑定三方
export async function bindThreeParty(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqBindThreePartyMessage', data, 'ReqBindThreeParty'),
            id: 400055
        }, {
            transformResponse: [getResponseData('ResBindThreePartyMessage')]
        })
    }
}

//请求设置隐私偏好
export async function setPrivacyPreferences(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqSetPrivacyPreferencesMessage', data, 'ReqSetPrivacyPreferences'),
            id: 400053
        }, {
            transformResponse: [getResponseData('ResSetPrivacyPreferencesMessage')]
        })
    }
}

//请求查看sessions
export async function checkSessionData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqCheckSessionDataMessage', data, 'ReqCheckSessionData'),
            id: 400059
        }, {
            transformResponse: [getResponseData('ResCheckSessionDataMessage')]
        })
    }
}

//请求游戏游戏数据
export async function getGameData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqGameDataMessage', data, 'ReqGameData'),
            id: 400019
        }, {
            transformResponse: [getResponseData('ResGameDataMessage')]
        })
    }
}

//请求游戏频道数据
export async function getGameChannelData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqGameChannelsDataMessage', data, 'ReqGameChannelsData'),
            id: 400021
        }, {
            transformResponse: [getResponseData('ResGameChannelsDataMessage')]
        })
    }
}


//请求申请代理
export async function applyAgent(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqApplyAgentMessage', data, 'ReqApplyAgent'),
            id: 400029
        }, {
            transformResponse: [getResponseData('ResApplyAgentMessage')]
        })
    }
}