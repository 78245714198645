import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

export async function getWelfareData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqWelfareDataMessage', data, 'ReqWelfareData'),
            id: 400603
        }, {
            transformResponse: [getResponseData('ResWelfareDataMessage')]
        })
    }
}

export async function receiveWelfare(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqReceiveWelfareMessage', data, 'ReqReceiveWelfare'),
            id: 400605
        }, {
            transformResponse: [getResponseData('ResReceiveWelfareMessage')]
        })
    }
}