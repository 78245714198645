<template>
    <div class="msgBox__modal__2kbbd" data-analytics="button" data-label="msg关闭"
        v-if="tanshow" style="display: block">
        <div class="msgBox__modal_HomeAreat_model__2obMO msgBox__modal_CuorsterAreat_model__wwzuA">
            <div class="msgBox__notice_header__kkFdX">
                <span class="msgBox__text__2ODn2" title="">更换头像</span><span
                    class="msgBox__close_icon__3J9a5" @click="changtanshow"></span>
            </div>
            <div class=" msgBox__notice_content_height__Z3O1m">
                <div class="my_head_con">
                   <img @click="onchangemd(item.headId)" :src="item.fileUrl" alt="" v-for="(item, index) in headList" :key="item.headId" 
                   style="width: 70px; cursor: pointer;">

                </div>
                <div class="msgBox__footer__3DHzZ"><button class="msgBox__confirm__3ypnx" type="button"
                        @click="changtanshow">关闭</button></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'userHead',
    props: {
        tanshow: {
            type: Boolean,
            required: true
        },
        headList: {
            type: Array,
            required: true
        }
    },
    methods: {
        onchangemd (headId) {
            this.$emit('onchangemd', headId)
        },
        changtanshow () {
            const that = this
            that.tanshow = !that.tanshow
        }
    }
}

</script>

<style scoped>
.my_head_con {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    gap: 5px;
    min-height: 300px;
    padding: 10px;
    overflow: auto;
}

</style>