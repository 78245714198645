<template>
    <div class="banner__wrapBanner__1FaRM">
        <div class="style__banner__28HI6 mySwiper">
            <div class="swiper-wrapper">
                <div v-for="(item, index) in bannerList" :key="index" class=" swiper-slide"
                    :style="`background-image: url(${item.fileUrl}); width: 1087px`"></div>
            </div>
            <div
                class="style__dots__2wknW swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
            </div>
            <div class="style__prev__14mIE" tabindex="0" role="button" aria-label="Previous slide"
                aria-controls="swiper-wrapper-3a5aceb5b3dfd6310"></div>
            <div class="style__next__3f6-g" tabindex="0" role="button" aria-label="Next slide"
                aria-controls="swiper-wrapper-3a5aceb5b3dfd6310"></div>
            <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
        </div>
    </div>
</template>


<script>
export default {
    name: "smallBanner",
    props: {
        bannerList: {
            required: true,
            type: Array
        }
    },
    mounted() {
        if (this.bannerList && this.bannerList.length > 0) {
            this.initSwiper();
        }
    },
    updated() {
        if (this.bannerList && this.bannerList.length > 0) {
            this.initSwiper();
        }
    },
    methods: {
        initSwiper() {
            console.log("initSwiper", this.bannerList);
            this.swiper = new Swiper('.mySwiper', {
                speed: 1000,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                    waitForTransition: true,
                },
                loop: true,
                navigation: {
                    nextEl: '.style__next__3f6-g',
                    prevEl: '.style__prev__14mIE',
                },
                pagination: {
                    el: '.style__dots__2wknW',
                    clickable: true,
                },
            });
        }
    },
    watch: {
        bannerList() {
            // 每当 bannerList 更新时，重新初始化 Swiper
            if (this.swiper) {
                this.swiper.update(); // 更新 Swiper 以包含新内容
            } else {
                this.initSwiper();
            }
        }
    }
}

</script>

<style scoped></style>