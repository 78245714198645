import axios from '@/axios'
import { getHallHost, getResponseData, getRquestData } from '@/utils/commonUtil'

// 请求交易信息
export async function getTransactionData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqTransactionDataMessage', data, 'ReqTransactionData'),
            id: 400203
        }, {
            transformResponse: [getResponseData('ResTransactionDataMessage')]
        })
    }
    
}

// 请求下注历史
export async function getBetHistoryData(data) {
    const host = getHallHost()
    if (host) {
        return axios.post( 'https://' + host +'/_api/graphql',{
            data: getRquestData('ReqBetHistoryDataMessage', data, 'ReqBetHistoryData'),
            id: 400201
        }, {
            transformResponse: [getResponseData('ResBetHistoryDataMessage')]
        })
    }
}

